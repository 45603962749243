import { isDev } from './env'
import { delay } from './misc'

export async function fetchWithRetry(
  url: URL | RequestInfo,
  options: RequestInit = {},
  maxRetries = 15,
): Promise<Response> {
  if (typeof window === 'undefined') {
    throw new Error(
      'fetchWithRetry is only available in the browser because it automatically handles bad status codes. This is a breaking change relative to fetch().',
    )
  }
  let lastError: Error | null = null

  for (let attempt = 0; attempt < (isDev ? 1 : maxRetries); attempt++) {
    try {
      const response = await fetch(url, options)

      if (response.status >= 500) {
        throw new Error(`Bad status: ${response.status} ${response.statusText}`)
      }

      // If the server responded, return the response regardless of status code
      return response
    } catch (error) {
      lastError = error as Error

      if (attempt + 1 < maxRetries) {
        console.warn(
          `Attempt ${attempt + 1} failed for url. Retrying...`,
          url,
          lastError,
        )
        await delay(500 * (attempt + 1))
      }
    }
  }

  throw lastError || new Error('Max retries reached')
}
