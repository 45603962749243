import { paths } from '@/services/sunrise'
import createClient, { Middleware } from 'openapi-fetch'
import createReactClient from 'openapi-react-query'
import { fetchWithRetry } from '../fetchWithRetry'

const middleware: Middleware = {
  async onResponse({ response }) {
    return response
  },

  async onRequest({ request }) {
    return request
  },
}

const client = createClient<paths>({
  baseUrl: process.env.NEXT_PUBLIC_SUNRISE_BASE!,
  fetch: fetchWithRetry,
  credentials: 'include',
  mode: 'cors',
})

client.use(middleware)

const react = createReactClient(client)

export const srpc = {
  client,
  ...react,
}

if (typeof window !== 'undefined') {
  ;(window as any).srpc = srpc
}
