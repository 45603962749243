import { isDev, isProd } from '@/util/env'
import { useEffect, useRef } from 'react'

declare global {
  interface Window {
    fbq: any
  }
}

function inject() {
  new Function(`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '312723518046397');
    fbq('track', 'PageView');
  `)()
}

function useMetaPixel() {
  const metaPixelLoadedRef = useRef(false)
  useEffect(() => {
    if (metaPixelLoadedRef.current) return
    if (isDev) return

    metaPixel.inject()
    metaPixelLoadedRef.current = true
  }, [])
}

function trackRegistration() {
  if (isProd) inject()
  window.fbq && window.fbq('track', 'CompleteRegistration')
}

function trackSubscription() {
  if (isProd) inject()
  window.fbq && window.fbq('track', 'Subscribe')
}

export const metaPixel = {
  inject,
  useMetaPixel,
  trackRegistration,
  trackSubscription,
}
