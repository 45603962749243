import { Draft, produce } from 'immer'
import { Emitter } from 'nanoevents'
import { WritableAtom } from 'nanostores'
import React, { createContext } from 'react'
import { useEffect, useState } from 'react'

export function classNames(...classes: (string | undefined | false)[]) {
  return classes.filter(Boolean).join(' ')
}

export type Truly<T> = Exclude<T, null | undefined | false | 0 | ''>

export function truly<T>(value: T): value is Truly<typeof value> {
  return !!value
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type ResultBox<T> = { v: T }

export function useConstant<T>(fn: () => T): T {
  const ref = React.useRef<ResultBox<T>>()

  if (!ref.current) {
    ref.current = { v: fn() }
  }

  return ref.current.v
}

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches
    }
    return false
  }

  const [matches, setMatches] = useState<boolean>(getMatches(query))

  function handleChange() {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)

    // Triggered at the first client-side load and if query changes
    handleChange()
    matchMedia.addEventListener('change', handleChange)

    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [query])

  return matches
}

export function withPageContext<T>(Child: React.FC<T>) {
  const Context = createContext<T>(null as any)
  return {
    Component: (props: T) => (
      <Context.Provider value={props}>
        <Child key={null} {...props} />
      </Context.Provider>
    ),
    Context,
  }
}

export function useEventEmitter<T extends object, K extends keyof T>(
  emitter: Emitter<T>,
  event: K,
  handler: T[K],
) {
  useEffect(() => {
    const unsub = emitter.on(event, handler)
    return () => unsub()
  }, [emitter, event, handler])
}

export function hide(reason: string) {
  // do nothing
  reason

  return false
}

export function updateStore<T>(
  store: WritableAtom<T>,
  updateFn: (prev: Draft<T>) => Draft<T> | void | undefined,
) {
  const prevState = store.get()
  const newState = produce(prevState, updateFn)
  store.set(newState)
}
