export const isDev = process.env.NODE_ENV === 'development'
export const isProd = !isDev
export const isBrowser = typeof window !== 'undefined'

declare global {
  function log(...args: any[]): void
}
global.log = (...args: any[]) => {
  return console.log(...args)
}
