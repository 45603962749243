import { createTRPCClient, httpLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import type { AppRouter } from '@/server/routers/root'
import { makeLoginUrl } from '@/util/makeLoginUrl'
import { fetchWithRetry } from './fetchWithRetry'

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return ''
  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`
  if (process.env.RENDER_INTERNAL_HOSTNAME)
    // reference for render.com
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

export const trpcVanilla = createTRPCClient<AppRouter>({
  links: [
    httpLink({
      fetch: async (...args) => {
        const result = await fetchWithRetry(...args)
        if ([401, 403].includes(result.status) && location && location.href) {
          location.href = makeLoginUrl({
            origin: location.origin,
            returnTo: location.href,
          })
        }
        return result
      },
      /**
       * If you want to use SSR, you need to use the server's full URL
       * @link https://trpc.io/docs/ssr
       **/
      url: `${getBaseUrl()}/api/trpc`,
      // You can pass any HTTP headers you wish here
      async headers() {
        return {
          // authorization: getAuthCookie(),
        }
      },
    }),
  ],
})

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        httpLink({
          fetch: async (...args) => {
            const result = await fetchWithRetry(...args)
            if (
              [401, 403].includes(result.status) &&
              location &&
              location.href
            ) {
              location.href = makeLoginUrl({
                origin: location.origin,
                returnTo: location.href,
              })
            }
            return result
          },
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${getBaseUrl()}/api/trpc`,
          // You can pass any HTTP headers you wish here
          async headers() {
            return {
              // authorization: getAuthCookie(),
            }
          },
        }),
      ],
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
})
