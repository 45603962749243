import { Subscription } from '@/services/billing'
import { srpc } from '@/util/srpc/client'
import { atom, computed, ReadableAtom } from 'nanostores'

type BillingState = {
  subscription: Subscription
  userId: string
}

const billingAtomWritable = atom<BillingState | undefined>()

const billingWithImagesLeft: ReadableAtom<
  | undefined
  | (BillingState & {
      imagesLeft: {
        bonusImagesLeft: number
        usageImagesLeft: number | typeof Number.POSITIVE_INFINITY
      }
    })
> = computed(billingAtomWritable, (billing) => {
  if (!billing) return undefined

  const subscription = billing?.subscription
  const usage = subscription?.usage
  const bonusImagesLeft = usage?.bonus
    ? Math.max(0, usage.bonus.totalImages - usage.bonus.usedImages)
    : 0

  let usageImagesLeft = 0
  if (subscription?.access.type === 'active') {
    switch (usage?.type) {
      case 'limited':
        usageImagesLeft = Math.max(0, usage.maxImages - usage.usedImages)
        break

      case 'unlimited':
        usageImagesLeft = Number.POSITIVE_INFINITY
        break

      default:
        usageImagesLeft = 0
        break
    }
  }

  return {
    ...billing,
    imagesLeft: {
      bonusImagesLeft,
      usageImagesLeft,
    },
  }
})

export const billingAtom = billingWithImagesLeft as ReadableAtom<
  typeof billingWithImagesLeft.value
>

export function updateBilling(state: BillingState): void {
  billingAtomWritable.set(state)
}

export async function fetchBilling(params: { userId: string }) {
  const { data: subscription } = await srpc.client.GET(
    '/api/public/billing/user_subscription_status',
  )
  if (subscription) {
    billingAtomWritable.set({
      subscription: subscription,
      userId: params.userId,
    })
  }

  return {
    success: !!subscription,
  }
}

export async function refetchBilling(): Promise<{ success: boolean }> {
  const billing = billingAtom.get()
  return billing
    ? await fetchBilling({ userId: billing.userId })
    : { success: false }
}
