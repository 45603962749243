import 'focus-visible'
import '@/styles/tailwind.css'
import type { AuthUser } from '@/services/auth'
import { AppProps } from 'next/app'
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client'
import { useEffect } from 'react'
import { fetchBilling } from '@/state/billing'
import { useRouter } from 'next/router'
import { trpc } from '@/util/trpc'
import { isDev } from '@/util/env'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { metaPixel } from '@/components/MetaPixel'
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'
import * as Sentry from '@sentry/nextjs'

declare global {
  interface Window {
    posthog: any
  }
}

if (typeof window !== 'undefined' && !isDev) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: 'https://www.newarc.ai/ingest',
    autocapture: false,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug()
    },
  })
}

export default trpc.withTRPC(App)
function App({ Component, pageProps }: AppProps) {
  return (
    <UserProvider>
      <PostHogProvider client={posthog}>
        <AppContextProvider>
          <Component {...pageProps} />
        </AppContextProvider>
      </PostHogProvider>
    </UserProvider>
  )
}

function AppContextProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()
  const { user } = useUser()
  const userId = user?.sub

  useEffect(() => {
    if (!user || !userId || (user as AuthUser).needs_verification) return

    posthog.identify(userId)

    window.Intercom?.('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'eia81hdr',
      name: user.name ?? user.nickname ?? 'Unknown',
      email: user.email!,
    })

    fetchBilling({ userId })
      .then(async ({ success }) => {
        if (!success) {
          router.push('/api/auth/logout')
        }
        return
      })
      .catch((e) => {
        console.error('error fetching billing', e)
        router.push('/api/auth/logout')
      })

    Sentry.setUser({ id: userId })
  }, [router, user, userId])

  const pingAsync = trpc.user.ping.useMutation().mutateAsync

  useEffect(() => {
    if (!user || !userId || (user as AuthUser).needs_verification) {
      return
    }

    const ping = async () => {
      const fingerprint = await getCurrentBrowserFingerPrint()
      return pingAsync({
        fp: String(fingerprint),
        ua: window.navigator.userAgent,
      })
    }

    ping()

    const oneHour = 1000 * 60 * 60
    const interval = setInterval(ping, oneHour)

    return () => clearInterval(interval)
  }, [user, userId, pingAsync])

  // copied from https://posthog.com/docs/libraries/next-js
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router])

  metaPixel.useMetaPixel()

  return <>{children}</>
}
